<template>
  <div :class="propsClass">
    <h2>{{$t("sabre.search-result.box.round-trip-price-per-person")}} <strong>{{`$${pricePerPerson}`}}</strong></h2>
    <h3>{{$t("sabre.search-result.box.total-for-persons", {count: persons})}}  {{`$${Math.round(totalPrice)}`}}</h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    totalPrice: {
      type: Number,
      default: 0,
    },
    propsClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      condition: 'GET_SABRE_CURRENT_SEARCH_CONDITION',
    }),
    adults() {
      const { condition } = this;
      return +condition.passengerComposition.adults || 0;
    },
    children() {
      const { condition } = this;
      return +condition.passengerComposition.children || 0;
    },
    babies() {
      const { condition } = this;
      return +condition.passengerComposition.babies || 0;
    },
    seniors() {
      const { condition } = this;
      return +condition.passengerComposition.seniors || 0;
    },
    persons() {
      const { adults, children, babies, seniors } = this;
      return adults + children + babies + seniors;
    },
    pricePerPerson() {
      const { totalPrice, persons } = this;
      return Math.round(totalPrice / persons);
    },
  },
};
</script>
